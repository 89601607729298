import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import BaiduMap from 'vue-baidu-map'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'; // 默认主题
import animated from 'animate.css'

Vue.use(animated)

Vue.use(BaiduMap, {
  /* Visit http://lbsyun.baidu.com/apiconsole/key for details about app key. */
  ak: 'xkkwALQ6o5WmDHyEn3XCWEAXQn1xCqne'
})
Vue.config.productionTip = false;

Vue.use(ElementUI, {
  size: 'small'
});

// 全局变量
import website from '@/config/siteConfig'

//全局注册website
Vue.prototype.$website = website;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
