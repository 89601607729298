import Vue from 'vue';
import VueRouter from 'vue-router';
import NProgress from 'nprogress' // Progress 进度条
import 'nprogress/nprogress.css'// Progress 进度条样式
import storage from '../utils/localstorage';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'homePage',
    component: ()=> import('../views/home.vue')
  },
  {
    path: '/download',
    name: 'downloadPage',
    component: ()=> import('../views/download.vue')
  },
  {
    path: '/product',
    name: 'productPage',
    component: ()=> import('../views/product.vue')
  },
  {
    path: '/about',
    name: 'aboutPage',
    component: ()=> import('../views/about.vue')
  },
  {
    path:'/pravicy',
    name:'pravicy',
    component:()=> import('../views/pravicy.vue')
  }

];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});


router.beforeEach((to, from, next) => {
  NProgress.start()
  next()
})

router.afterEach((to,from)=>{
  storage.set('currentRoute',to.fullPath);
  NProgress.done() // 结束Progress
})
export default router;
